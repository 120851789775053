*::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  z-index: 11;
  background-color:#28395300;
}

*::-webkit-scrollbar-thumb{
  background: hsla(210, 100%, 16%, 0.441);
  border-radius: 2px;
}

input:focus {
  outline: none;
}

body, button, input, textarea{
  color: #000;
  background-color: #fff;
}

.App{
  background-color: #8fe9ff36;
}

.template-select {
  width: 88%;
}

.file-upload {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  cursor: pointer;
  width: 30%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  color: #fff;
  background: #1876d2;
  border-radius: 6px;
  padding: 6px 0rem;
  gap: 10px;
  height: 2.8rem;
  justify-content: center;
}

/* styles.css */
.sticky-first-column .MuiDataGrid-window {
  overflow: visible;
}

.sticky-first-column .MuiDataGrid-colCell:first-child {
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: white;
}

.sticky-first-column .MuiDataGrid-cell:first-child::before {
  content: '';
  position: absolute;
  top: -1px;
  left: -1px;
  height: calc(100% + 2px);
  width: 100%;
  background-color: white;
  z-index: -1;
}


.file-upload p{
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
input[type="file"] {
  display: none;
}


.home-container{
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  margin-top: auto;
  margin-bottom: auto;
  height: 60vh
}

.wa-user {
  padding: 5px 0px !important;
  border-top: 3px solid #f3f3f3 !important;
  cursor: pointer;
  text-align: left !important;
  height: 100%;
  width: 100%;
  max-width: 60%;
}

.wa-message {
  padding: 15px 20px;
}

.msg{
  padding: 8px 10px;
  border-radius: 10px;
  color: #fff;
  min-height: 40px;
}

.left-msg {
  background-color: #1876d2;
}

.right-msg{
  background-color: #18a7d2;
}

.time-txt {
  font-size: 10px !important;
  color: #7a7a7a;
}

.welcome-texts{
  font-family: 'Dosis', sans-serif;
  font-size: 3rem;
  /* text-align: center; */
}

.greeting-texts{
  font-size: 2.8rem;
  /* text-align: center; */
}

.template-select-container{
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding: 20px 24px;
}

@media only screen and (max-width: 600px) {
  .home-container{
    display: flex;
    flex-direction: column-reverse;
    min-height: 66vh;
    text-align: center;
    height: max-content;
  }

  *::-webkit-scrollbar {
    width: 1px;
    height: 1px;
    z-index: 11;
    background-color:#28395300;
  }

  .welcome-texts{
    font-size: 2rem
  }

  .link {
    text-decoration: underline;
    color: #1876d2;
    overflow-wrap: break-word;
    cursor: pointer;
  }

  .greeting-texts{
    font-size: 1.8rem
  }

  .grid-container{
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .file-upload {
    width: 100%;
  }

  .template-select {
    width: 100%;
  }

  .template-select-container{
    flex-direction: column;
    gap: 10px;
    width: 88%;
  }

  *::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }
}